import {GlobalDataInterface} from "../context/GlobalContext.tsx";
import fabric, {BoardField, CoinField} from "./fabricExtended.ts";
import {Pattern} from "fabric/fabric-impl";
import {RouletteConfigurationType} from "../data/RouletteConfiguration.ts";
import {FabricJSEditor} from "fabricjs-react";

export type CoinValues = 1 | 5 | 10 | 20 | 50 | 100 | 500 | 1000

export const coinRangesConfiguration = [
    {min: -Infinity, max: 4, pattern: '1-4.png', font: 12},
    {min: 5, max: 9, pattern: '5-9.png', font: 12},
    {min: 10, max: 24, pattern: '10-24.png', font: 12},
    {min: 25, max: 49, pattern: '25-49.png', font: 12},
    {min: 50, max: 99, pattern: '50-99.png', font: 12},
    {min: 100, max: 499, pattern: '100-499.png', font: 12},
    {min: 500, max: 999, pattern: '500-999.png', font: 10},
    {min: 1000, max: Infinity, pattern: '1000.png', font: 10},
]

export function getCoinRangeConfiguration(value: number){
    for(let range of coinRangesConfiguration){
        if(value >= range.min && value <= range.max){
            return range;
        }
    }
}

export function getProperCoinPattern(value: number, globalData: GlobalDataInterface)
{
    return new Promise<Pattern>((resolve, reject) => {
        const range = getCoinRangeConfiguration(value);
        fabric.util.loadImage(globalData.pluginUrl + 'src/images/coins/'+range.pattern, (pattern) => {
            resolve(new fabric.Pattern({
                source: pattern,
                repeat: 'repeat'
            }))
        })
    })

}

export function getProperCoinFontSizeFromConfiguration(value: number, configuration: RouletteConfigurationType){
    const range = getCoinRangeConfiguration(value);
    return range.font * configuration.scale

}
export async function getCoinFieldCircle(radius: number, value: number, globalData: GlobalDataInterface){
    const pattern = await getProperCoinPattern(value, globalData);
    return new fabric.Circle({
        radius: radius,
        left: 0,
        top: 0,
        fill: pattern,
        strokeWidth: 0,
        // borderColor: '#00ff00',
        originX: 'center',
        originY: 'center'
    })
    // return new fabric.Circle({
    //     radius: radius,
    //     left: 0,
    //     top: 0,
    //     fill: '#d5c75c',
    //     strokeWidth: 0,
    //     // borderColor: '#00ff00',
    //     originX: 'center',
    //     originY: 'center'
    // })
}
export function getCoinFieldText(text: string, configuration: RouletteConfigurationType){
    return new fabric.Text(text, {
        fontSize: getProperCoinFontSizeFromConfiguration(parseInt(text), configuration),
        originX: 'center',
        originY: 'center',
    });
}

export async function getCoinField(value: number, field: BoardField, globalData: GlobalDataInterface, configuration: RouletteConfigurationType)
{
    if(value === 0) return null;
    const currency = globalData.currency;

    const circle = await getCoinFieldCircle(configuration.coinSize / 2 * configuration.scale, value, globalData)

    var text = getCoinFieldText(value.toString(), configuration)
    //@ts-ignore
    const coinField = new fabric.CoinField([circle, text],{
        width: field.width,
        height: field.height,
        left: field.left,
        top: field.top,
        // fill: '#0000ff',
        // background: '#0000ff',
        betData: {
            idField: field.boardData.id,
            value: value
        },
        selectable: false,
        evented: false,
        hoverCursor: 'pointer',
    })

    coinField.scale(field.scaleX);

    // console.log(coinField);
    return coinField;



    // const imgUrl = `${globalData.pluginUrl}src/images/coins/${value}.png`;
    //
    // return new Promise(resolve => {
    //     fabric.Image.fromURL(imgUrl, (img) => {
    //         img.scaleToWidth(size);
    //         img.scaleToHeight(size);
    //         const scaleX = img.scaleX as number;
    //         const scaleY = img.scaleY as number;
    //         img.scaleY = scaleY * globalData.scale;
    //         img.scaleX = scaleX * globalData.scale;
    //         resolve(img);
    //     })
    // })



}


export const getBoardFieldCoinByID = (boardFieldId: string, editor: FabricJSEditor | undefined): null | CoinField => {

    for(const object of editor?.canvas.getObjects('coinField')){
        if (object.type !== 'coinField') continue;
        const coinField: CoinField = object as CoinField;
        if (coinField.betData.idField === boardFieldId) {
            return coinField;
        }
    }

    return null;
}
export const getBoardFieldCoin = (target: BoardField | null, editor: FabricJSEditor | undefined): null | CoinField => {
    if (!target) return null;
    if (target.type !== 'boardField') return null;

    return getBoardFieldCoinByID(target.boardData.id, editor);

}

export const modifyCoinField = async (coinField: CoinField, modifier: number, editor: FabricJSEditor | undefined, globalData: GlobalDataInterface) => {
    coinField.betData.value += modifier;
    if(coinField.betData.value <= 0){
        editor?.canvas.remove(coinField);
        return null;
    }
    coinField.getObjects('text').forEach((text: fabric.Text) => {
        text.set('text', coinField.betData.value.toString());
        text.dirty = true;
    })
    const newPattern = await getProperCoinPattern(coinField.betData.value, globalData);
    coinField.getObjects('circle').forEach((circle: fabric.Circle) => {
        circle.set('fill', newPattern);
        circle.dirty = true;
    })
    coinField.dirty = true;
    return coinField;
}


