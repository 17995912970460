import React, {createContext, useContext, useReducer} from 'react';
import {CurrentCoinField} from "../objects/fabricExtended.ts";
import {getTotalBets} from "../data/DataUtils.ts";
import {RouletteFieldNumberType, RouletteType} from "../data/RouletteConfiguration.ts";

export interface GlobalDataInterface {
    rouletteType: RouletteType,
    pluginUrl: string,
    aspectRatio: number,
    scale: number,
    currency: string,
    currentCoin: null | CurrentCoinField,
    totalBet: number,
    winningNumber: null | RouletteFieldNumberType
}

const globalData: GlobalDataInterface = {
    rouletteType: 'european',
    pluginUrl: '',
    aspectRatio: 1,
    scale: 1,
    currency: '$',
    currentCoin: null,
    totalBet: 0,
    winningNumber: null
}
const GlobalContext = createContext<GlobalDataInterface>(globalData);

//@ts-ignore
const GlobalDispatchContext = createContext(null);


interface GlobalProviderProps {
    pluginUrl?: string | null,
    children: React.ReactNode;
}

export function GlobalProvider(props: GlobalProviderProps) {

    //@ts-ignore
    const [globalDataProvider, dispatch] = useReducer<GlobalDataInterface, (state: GlobalDataInterface, action: GlobalReducerActionInterface) => any>(
        globalReducer,
        {
            ...globalData,
            pluginUrl: props.pluginUrl,
        }
    );
    return (
        <GlobalContext.Provider value={globalDataProvider}>
            <GlobalDispatchContext.Provider value={dispatch}>
                {props.children}
            </GlobalDispatchContext.Provider>
        </GlobalContext.Provider>
    );
}

export function useGlobal() {
    return useContext<GlobalDataInterface>(GlobalContext);
}

export function useGlobalDispatch() {
    return useContext<(param: GlobalReducerActionInterface) => void>(GlobalDispatchContext);
}

interface GlobalReducerActionInterface {
    type: GlobalReducerActionTypes,
    payload?: any
}
type GlobalReducerActionTypes = 'setPluginUrl' | 'setScaleAndAspectRatio' | 'setCurrentCoin' | 'recalculateTotalBet' | 'setRouletteType' | 'setWinningNumber'
function globalReducer(state: GlobalDataInterface, action: GlobalReducerActionInterface) {
    switch (action.type) {
        case 'setPluginUrl': {
            return {
                ...state,
                pluginUrl: action.payload
            }
        }
        case 'setScaleAndAspectRatio': {
            return {
                ...state,
                scale: action.payload.scale,
                aspectRatio: action.payload.aspectRatio
            }
        }
        case 'setCurrentCoin': {
            let currentCoin = null;
            if(action.payload.currentCoin){
                currentCoin = action.payload.currentCoin
            }
            return {
                ...state,
                currentCoin: currentCoin,
            }
        }
        case 'recalculateTotalBet': {
            return {
                ...state,
                totalBet: getTotalBets(action.payload),
            }
        }
        case 'setRouletteType': {
            return {
                ...state,
                totalBet: 0,
                currentCoin: null,
                rouletteType: action.payload,
            }
        }
        case 'setWinningNumber': {
            return {
                ...state,
                winningNumber: action.payload,
            }
        }

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}


