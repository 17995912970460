import {fabric} from "fabric";
import {Group, Rect} from "fabric/fabric-impl";

export interface BoardData {
    id: string,
    number: number | null,
    multiplier: number,
    bet: Array<number>,
}
export interface BoardField extends Rect {
    boardData: BoardData
}
//@ts-ignore
fabric.BoardField = fabric.util.createClass(fabric.Rect, {

    type: 'boardField',
    boardData: {},

    initialize: function(element, options) {
        // console.log(options);
        // console.log(element);
        element.selectable = false;
        element.hoverCursor = 'pointer';

        this.callSuper('initialize', element, options);
        options && this.set('boardData', options.boardData);
    },

    toObject: function() {
        return fabric.util.object.extend(this.callSuper('toObject'), { boardData: this.boardData });
    }

});

//@ts-ignore
fabric.BoardField.fromObject = function (object, callback) {
    return fabric.Object._fromObject("BoardField", object, callback);
};



//COIN

export interface BetData {
    idField: string,
    value: number
}
export interface CoinField extends Group {
    betData: BetData
}
//@ts-ignore
fabric.CoinField = fabric.util.createClass(fabric.Group, {

    type: 'coinField',
    betData: {},

    initialize: function(element, options) {
        // element.selectable = false;
        // element.hoverCursor = 'pointer';

        this.callSuper('initialize', element, options);
        options && this.set('betData', options.betData);
    },

    toObject: function() {
        return fabric.util.object.extend(this.callSuper('toObject'), { betData: this.betData });
    }

});

//@ts-ignore
fabric.CoinField.fromObject = function (object, callback) {
    return fabric.Object._fromObject("CoinField", object, callback);
};



export interface CurrentCoinField extends Group {
    coinData: {
        value: number,
        defaultPosition: {
            x: number,
            y: number
        }
    }
}
//@ts-ignore
fabric.CurrentCoinField = fabric.util.createClass(fabric.Group, {

    type: 'currentCoinField',
    coinData: {},

    initialize: function(element, options, isAlreadyGrouped) {
        // console.log(element, options);
        options = options || {};
        // console.log(this);

        this.callSuper('initialize', element, options, isAlreadyGrouped);
        // console.log(this);
        options
        && this.set('coinData', options.coinData)
        && this.set('selectable', options.selectable)
        && this.set('hoverCursor', options.hoverCursor)
        ;
    },

    toObject: function(propertiesToInclude) {
        const obj = fabric.util.object.extend(this.callSuper('toObject'), {
            ...propertiesToInclude,
            coinData: this.coinData,
            selectable: this.selectable,
            hoverCursor: this.hoverCursor,
        });
        return obj;
    }



});

//@ts-ignore
// fabric.CurrentCoinField.fromObject = function (object, callback) {
//     console.log(object);
//     return fabric.Object._fromObject("CurrentCoinField", object, callback);
// };

//@ts-ignore
fabric.CurrentCoinField.fromObject = function(object, callback) {
    var objects = object.objects,
        options = fabric.util.object.clone(object, true);
    delete options.objects;
    if (typeof objects === 'string') {
        // it has to be an url or something went wrong.
        fabric.loadSVGFromURL(objects, function (elements) {
            var group = fabric.util.groupSVGElements(elements, object, objects);
            var clipPath = options.clipPath;
            delete options.clipPath;
            group.set(options);
            if (clipPath) {
                fabric.util.enlivenObjects([clipPath], function(elivenedObjects) {
                    group.clipPath = elivenedObjects[0];
                    callback && callback(group);
                });
            }
            else {
                callback && callback(group);
            }
        });
        return;
    }
    fabric.util.enlivenObjects(objects, function (enlivenedObjects) {
        fabric.util.enlivenObjectEnlivables(object, options, function () {
            callback && callback(new fabric.CurrentCoinField(enlivenedObjects, options, true));
        });
    });
};

export default fabric;
