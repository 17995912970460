import {useEffect} from "react";
import {FabricJSEditor} from "fabricjs-react";
import {useGlobal, useGlobalDispatch} from "../context/GlobalContext.tsx";
import fabric, {CurrentCoinField} from "../objects/fabricExtended.ts";
import {recalculatePositions} from "../data/DataUtils.ts";
import {getRouletteConfiguration, RouletteType} from "../data/RouletteConfiguration.ts";
import {getCurrentCoinField} from "../objects/CurrentCoinField.ts";

export default function useLoadBoard(editor: FabricJSEditor | undefined, type: RouletteType)
{
    const globalDispatch = useGlobalDispatch();
    const globalData = useGlobal();

    const configuration = getRouletteConfiguration(type);

    useEffect(() => {
        if(!editor) return;

        fabric.Image.fromURL(globalData.pluginUrl + 'src/images/boards/'+configuration.board, function (img) {

            const imgWidth = img.width + configuration.widthModifier as number;
            const imgHeight = img.height as number;
            const aspectRatio = imgWidth / imgHeight;
            const width = configuration.width > window.innerWidth ? window.innerWidth : configuration.width;
            const height = width / aspectRatio;
            const scale = width / imgWidth;
            editor?.canvas.setWidth(width);
            editor?.canvas.setHeight(height);
            if (editor?.canvas) {
                editor.canvas.selection = false;
                editor.canvas.fireRightClick = true;
                editor.canvas.fireMiddleClick = true;
                editor.canvas.stopContextMenu = true;
            }

            const positions = recalculatePositions(JSON.parse(JSON.stringify(configuration.positions)), scale)
            const currentCoinFields: CurrentCoinField[] = [];

            positions.objects = positions.objects.filter((object) => {
                if(object.type !== 'currentCoinField') return true;
                currentCoinFields.push(object);

                return false;
            })



            editor?.canvas.loadFromJSON(positions,  function () {
                for (const coinField of currentCoinFields){
                    //@ts-ignore
                    getCurrentCoinField(coinField.coinData.value,  configuration.coinSize / 2 * configuration.scale, coinField.left, coinField.top, globalData, configuration).then((field) => {
                        field.scale(scale);
                        editor?.canvas.add(field);
                    })
                }
                editor?.canvas.renderAll();


                editor?.canvas.setBackgroundImage(img, editor?.canvas.renderAll.bind(editor?.canvas), {
                    scaleX: scale,
                    scaleY: scale
                });


            });


            globalDispatch({
                type: 'setScaleAndAspectRatio',
                payload: {
                    scale: scale,
                    aspectRatio: aspectRatio
                }
            })


            // editor?.canvas.removeListeners();


        });
    }, [fabric, editor]);

}
