import {GlobalDataInterface} from "../context/GlobalContext.tsx";
import fabric, {CurrentCoinField} from "./fabricExtended.ts";
import {CoinValues, getCoinFieldCircle, getCoinFieldText} from "./CoinField.ts";
import {RouletteConfigurationType} from "../data/RouletteConfiguration.ts";


export async function getCurrentCoinField(value: CoinValues, radius: number, x: number, y: number, globalData: GlobalDataInterface, configuration: RouletteConfigurationType): Promise<CurrentCoinField> {
    const scale = globalData.scale;
    const currency = globalData.currency;
    const circle = await getCoinFieldCircle(radius, value, globalData)
    const text = getCoinFieldText(value.toString(), configuration)
    //@ts-ignore
    const currentCoinField = new fabric.CurrentCoinField([circle, text], {
        width: radius*2,
        height: radius*2,
        left: x,
        top: y,
        // scaleX: scale,
        // scaleY: scale,
        coinData: {
            defaultPosition: {
                x: x * scale,
                y: y * scale,
            },
            value: value
        },
        selectable: false,
        evented: true,
        hoverCursor: 'pointer',
    })

    currentCoinField.scale(scale);

    return currentCoinField;



    // const imgUrl = `${globalData.pluginUrl}src/images/coins/${value}.png`;
    //
    // return new Promise(resolve => {
    //     fabric.Image.fromURL(imgUrl, (img) => {
    //         img.scaleToWidth(size);
    //         img.scaleToHeight(size);
    //         const scaleX = img.scaleX as number;
    //         const scaleY = img.scaleY as number;
    //         img.scaleY = scaleY * globalData.scale;
    //         img.scaleX = scaleX * globalData.scale;
    //         resolve(img);
    //     })
    // })



}
