import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import {useEffect, useRef} from "react";
import {useGlobal, useGlobalDispatch} from "../../context/GlobalContext.tsx";
import {CoinValues, getCoinField} from "../../objects/CoinField.ts";
import useEditorResize from "../../hooks/useEditorResize.ts";
import {Image} from "fabric/fabric-impl";
import {getBoardField} from "../../objects/BoardField.ts";
import {AllBetsType, getBets, recalculatePositions, uniqueID, uniqueValues} from "../../data/DataUtils.ts";
import fabric, {BoardField, CoinField} from "../../objects/fabricExtended.ts";
import useBoardEvents from "../../hooks/useBoardEvents.ts";
import BoardConfigurator from "../BoardConfigurator.tsx";
import useLoadBoard from "../../hooks/useLoadBoard.ts";
import Calculator from "../Calculator.tsx";
import {getRouletteConfiguration, RouletteType} from "../../data/RouletteConfiguration.ts";
import {HistoryEventsProvider} from "../../context/ActionsHistoryContext.tsx";
import ActionButtons from "../ActionButtons.tsx";
import CanvasTouchScrollFixer from "../CanvasTouchScrollFixer.tsx";

export default function TripleRoulette() {
    const {editor, onReady} = useFabricJSEditor();
    const globalDispatch = useGlobalDispatch();
    const globalData = useGlobal();

    const type: RouletteType = 'triple';
    const configuration = getRouletteConfiguration(type)

    useEditorResize(editor, type);
    useBoardEvents(editor, type);
    useLoadBoard(editor, type);


    return (
        <div className="RouletteBase TripleRoulette">
            {/*<BoardConfigurator editor={editor} type={type}/>*/}

            <Calculator configuration={configuration} editor={editor}/>

            <CanvasTouchScrollFixer>
                <FabricJSCanvas className="RouletteBase__canvas TripleRoulette__canvas" onReady={onReady}/>
            </CanvasTouchScrollFixer>

            <ActionButtons editor={editor} rouletteType={type}/>

        </div>
    )
}
