import fabric, {BoardField} from "./fabricExtended.ts";
import {uniqueID} from "../data/DataUtils.ts";
import {FabricJSEditor} from "fabricjs-react";


interface GetBoardFieldOptionsInterface {
    id: string,
    left?: number,
    top?: number,
    width?: number,
    height?: number,
    number?: number | null,
    multiplier?: number,
    bet: [number],
    scale?: number,
    color?: string
}
export function getBoardField(options: GetBoardFieldOptionsInterface){

    const defaults = {
        scale: 1,
        color: '#00ff00',
        number: null,
        multiplier: 1,
        width: 37,
        height: 52,
        left: 0,
        top: 0
    }
    options = Object.assign(defaults, options);


    const polygon = new fabric.Polygon([
        {x: 39-options.width/2, y: 0-options.height/2},
        {x: 54-options.width/2, y: 0-options.height/2},
        {x: 54-options.width/2, y: 160-options.height/2},
        {x: 39-options.width/2, y: 160-options.height/2},
        {x: 0-options.width/2, y: 79-options.height/2},
        {x: 39-options.width/2, y: 0-options.height/2},
    ])
    //@ts-ignore
    // polygon.scale(options.scale);
    //@ts-ignore
    return new fabric.BoardField({
        width: options.width,
        height: options.height,
        scaleX: options.scale,
        scaleY: options.scale,
        // stroke: '#00ff00',
        opacity: 0.1,
        fill: options.color,
        left: options.left * options.scale,
        top: options.top * options.scale,
        // clipPath: polygon,
        boardData: {
            number: options.number,
            bet: options.bet,
            id: options.id,
            multiplier: options.multiplier
        }
        // strokeWidth: 0,
        // strokeUniform: true
    })

}

export function getBoardFieldByID(id: string, editor: FabricJSEditor | undefined){
    let boardField: BoardField | null = null;
    //@ts-ignore
    const fields:BoardField[] = editor?.canvas.getObjects('boardField');
    for(const field of fields){
        if(field.boardData.id === id){
            return field;
        }
    }
    return null;
}
