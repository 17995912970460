// import { useState } from 'react'
import '../../scss/components/RouletteSelect.scss'
import EuropeanRoulette from "./roulettes/EuropeanRoulette.tsx";
import {HistoryEventsProvider} from "../context/ActionsHistoryContext.tsx";
import {useState} from "react";
import {RouletteType} from "../data/RouletteConfiguration.ts";
import AmericanRoulette from "./roulettes/AmericanRoulette.tsx";
import TripleRoulette from "./roulettes/TripleRoulette.tsx";
import {useGlobal} from "../context/GlobalContext.tsx";

function RouletteSelect() {

    const globalData = useGlobal();

    return (
    <div className="RouletteSelect" onContextMenu={(e) => e.preventDefault()}>
        <HistoryEventsProvider>

            {globalData.rouletteType === 'european' &&
                <EuropeanRoulette/>
            }
            {globalData.rouletteType === 'american' &&
                <AmericanRoulette/>
            }
            {globalData.rouletteType === 'triple' &&
                <TripleRoulette/>
            }

        </HistoryEventsProvider>
    </div>
    )
}

export default RouletteSelect
