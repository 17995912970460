import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import {useGlobal, useGlobalDispatch} from "../../context/GlobalContext.tsx";
import useEditorResize from "../../hooks/useEditorResize.ts";

import useBoardEvents from "../../hooks/useBoardEvents.ts";
import BoardConfigurator from "../BoardConfigurator.tsx";
import useLoadBoard from "../../hooks/useLoadBoard.ts";
import Calculator from "../Calculator.tsx";
import {getRouletteConfiguration, RouletteType} from "../../data/RouletteConfiguration.ts";
import ActionButtons from "../ActionButtons.tsx";
import CanvasTouchScrollFixer from "../CanvasTouchScrollFixer.tsx";

export default function EuropeanRoulette() {
    const {editor, onReady} = useFabricJSEditor();
    const globalDispatch = useGlobalDispatch();
    const globalData = useGlobal();

    const type: RouletteType = 'european';

    const configuration = getRouletteConfiguration(type)

    useEditorResize(editor, type);
    useBoardEvents(editor, type);
    useLoadBoard(editor, type);



    return (
        <div className="RouletteBase EuropeanRoulette">
            {/*<BoardConfigurator editor={editor} type={type}/>*/}
            <Calculator configuration={configuration} editor={editor}/>

            <CanvasTouchScrollFixer>
                <FabricJSCanvas className="RouletteBase__canvas EuropeanRoulette__canvas" onReady={onReady}/>
            </CanvasTouchScrollFixer>

            <ActionButtons editor={editor} rouletteType={type}/>

        </div>
    )
}
