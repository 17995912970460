import {useEffect, useState} from "react";
import {useGlobal, useGlobalDispatch} from "../context/GlobalContext.tsx";
import {FabricJSEditor} from "fabricjs-react";
import {AllBetsType, getBets, getTotalBets, MathFactorial} from "../data/DataUtils.ts";
import {CheckIcon, ExpectedIcon, LoseIcon, WinIcon} from "../data/Icons.ts";
import {
    RouletteConfiguration,
    RouletteConfigurationType,
    RouletteFieldNumberType
} from "../data/RouletteConfiguration.ts";



type gameOptionTypes = 'single' | 'simulation' | 'live' | 'online_dealer' | 'online'
type gameOptionsType = {
    [key: gameOptionTypes]: {betsPerHour: number, label: string}
}

const simulationGameOptionKey = 'simulation';
const gameOptions: gameOptionsType = {
    single: {betsPerHour: 0, label: 'Single Bet'},
    [simulationGameOptionKey]: {betsPerHour: 0, label: 'Simulation'},
    live: {betsPerHour: 30, label: 'Live In-Person Casino (30 bets per hour)'},
    online_dealer: {betsPerHour: 110, label: 'Online With Dealer (110 bets per hour)'},
    online: {betsPerHour: 220, label: 'Online Without Dealer (220 bets per hour)'},
}


type CalculatorProps = {
    configuration: RouletteConfigurationType
    editor: FabricJSEditor | undefined
}
export default function Calculator(props: CalculatorProps) {

    const globalData = useGlobal();
    const globalDataDispatch = useGlobalDispatch();
    const {editor, configuration} = props;
    const totalFields = configuration.numbers.length;

    const [possibleOutcomes, setPossibleOutcomes] = useState(new Map());
    const [possibleOutcomesElements, setPossibleOutcomesElements] = useState([]);
    const [expectedValue, setExpectedValue] = useState('');
    const [betsPerHour, setBetsPerHour] = useState(0);
    const [gameHours, setGameHours] = useState(1);
    const [gameOption, setGameOption] = useState<gameOptionTypes>('single');
    const [winningNumber, setWinningNumber] = useState<RouletteFieldNumberType | null>(null);
    const [winningNumberHistory, setWinningNumberHistory] = useState<RouletteFieldNumberType[]>([]);

    const [isSimulation, setIsSimulation] = useState(false);
    const [simulationValue, setSimulationValue] = useState<number>(0);
    // Function to calculate and display potential profits and expected value (EV)
    const getPossibleOutcomes = () => {
        const bets = getBets(editor)
        let ev = 0; // Initialize expected value

        for (let id in bets) {
            const betAmount = bets[id].value;
            const probabilityWin = bets[id].field.boardData.bet.length / totalFields;
            const probabilityLoss = 1 - probabilityWin;

            let profit = betAmount * bets[id].field.boardData.multiplier;
            let loss = -betAmount;

            // Calculate expected value for individual bets
            ev += (probabilityWin * profit) + (probabilityLoss * loss);

        }

        let possibilities = new Map();
        for(let i = 0; i < totalFields; i++) {
            let simulatedProfit = simulateNumberProfit(bets, i);
            let currentPossibility = 1;
            if(possibilities.has(simulatedProfit)){
                currentPossibility += possibilities.get(simulatedProfit);
            }

            possibilities.set(simulatedProfit, currentPossibility);
        }

        possibilities = new Map([...possibilities].sort((a, b) => {
            return a[0] - b[0];
        }))


        setPossibleOutcomes((prev) => {
            return possibilities;
        });

        console.clear();
        possibilities.forEach((possibility, value) => {
            let percent = (possibility / totalFields * 100).toFixed(2);
            if(value >= 0){
                console.log(`%c${percent}% chance of profiting $${value}`, 'color: #00ff00')
            }else{
                console.log(`%c${percent}% chance of losing $${Math.abs(value)}`, 'color: #ff0000')
            }
        })
        console.log(`%cExpected Value: $${ev.toFixed(2)}`, 'color: #00ffff')

        setExpectedValue((prev) => {
            return ev.toFixed(3);
        })

        return {possibilities, ev};
    }

    const getPossibleOutcomesElements = () => {
        // let possibleOutcomesElements = [];
        let {possibilities, ev} = getPossibleOutcomes();
        const betsCount = calculateGamesCount();


        if(betsCount === 1) //single bet
        {
            const newPossibleOutomesElements = [];
            possibilities.forEach((possibility, value) => {
                let percent = (possibility / totalFields * 100).toFixed(2);
                if(value >= 0){
                    newPossibleOutomesElements.push(
                        <li className="Calculator__result Calculator__result--win" key={value}><WinIcon/> {`${percent}% chance of profiting $${value}`}</li>
                    );
                }else{
                    newPossibleOutomesElements.push(
                        <li className="Calculator__result Calculator__result--lose" key={value}><LoseIcon/> {`${percent}% chance of losing $${Math.abs(value)}`}</li>
                    );
                }
            })

            if(ev){
                newPossibleOutomesElements.push(
                    <li className="Calculator__result Calculator__result--expected" key={ev}><ExpectedIcon/> {`Expected Value: -$${Math.abs(ev * betsCount).toFixed(2)}`}</li>
                );
            }

            setPossibleOutcomesElements(newPossibleOutomesElements);

        }else{ //strategy

            const randomFromArray = (randomArray) => {
                return randomArray[Math.floor(Math.random()*randomArray.length)]
            }
            const arraySum = (array) => array.reduce((partialSum, a) => partialSum + a, 0);

            const multipleRandomFromWeightedArray = (array, weightArray, resultsCount) => {
                let randomArray = [];
                array.forEach((item, index) => {
                    var clone = Array(weightArray[index]).fill(item);
                    randomArray.push(...clone);
                });

                const result = [];
                for(let i = 0; i < resultsCount; i++) {
                    result.push(randomFromArray(randomArray));
                }

                return result;
            }

            const simulate_session = (num_trials, outcomes, probabilities) => {
                const simulatedProfits = multipleRandomFromWeightedArray(outcomes, probabilities, num_trials)
                const totalProfit = arraySum(simulatedProfits);
                return totalProfit > 0;
            }

            const monte_carlo_simulation = (num_trials, num_simulations, outcomes, probabilities) => {
                let profitable = 0;
                for(let i = 0; i < num_simulations; i++){
                    if (simulate_session(num_trials, outcomes, probabilities)){
                        profitable++;
                    }
                }
                return profitable / num_simulations;
            }
            /*
             import numpy as np

             # Function to simulate a single session
             def simulate_session(num_trials, outcomes, probabilities):
                 results = np.random.choice(outcomes, size=num_trials, p=probabilities)
                 total_profit = np.sum(results)
                 return total_profit > 0

             # Generalized Monte Carlo simulation
             def monte_carlo_simulation(num_trials, num_simulations, outcomes, probabilities):
                 profitable_sessions = sum(simulate_session(num_trials, outcomes, probabilities) for _ in range(num_simulations))
                 prob_profitable = profitable_sessions / num_simulations
                 return prob_profitable

             # Example usage
             if __name__ == "__main__":
                 # Define the betting outcomes and their probabilities
                 outcomes = [-30, 330]
                 probabilities = [34/37, 3/37]

                 # Simulation parameters
                 num_trials = 110
                 num_simulations = 100000

                 # Run the Monte Carlo simulation
                 prob_profitable = monte_carlo_simulation(num_trials, num_simulations, outcomes, probabilities)

                 print(prob_profitable)


     */
            const newPossibleOutomesElements = [];

            if(ev){
                newPossibleOutomesElements.push(
                    <li className="Calculator__result Calculator__result--lose" key={'strategyExpectedValue'}><LoseIcon/> {`You are expected to lose $${Math.abs(ev * betsCount).toFixed(2)}`}</li>
                );
            }


            let profitChancePercent = monte_carlo_simulation(betsCount, 100000, [...possibilities.keys()], [...possibilities.values()])


            newPossibleOutomesElements.push(
                <li className="Calculator__result Calculator__result--win" key={'strategyProfitPercent'}><WinIcon/> {`You have about a ${(profitChancePercent*100).toFixed(2)}% chance of making a profit.`}</li>
            );

            setPossibleOutcomesElements(newPossibleOutomesElements);
        }
    }

    const simulateNumberProfit = (bets: AllBetsType, number: number) => {
        let profit = 0;
        for (let id in bets) {
            const betAmount = bets[id].value;
            let isWinning = bets[id].field.boardData.bet.indexOf(number) !== -1;
            if(!isWinning){
                profit -= betAmount;
                continue;
            }
            profit += betAmount * bets[id].field.boardData.multiplier;
        }

        return profit;
    }

    const handleGameOptionChange = (ev) => {

        let value:gameOptionTypes = ev.target.value;
        setIsSimulation(value === simulationGameOptionKey);
        setGameOption(value);
        setBetsPerHour(gameOptions[value].betsPerHour)
        if(possibleOutcomes){
            resetOutcomes();
        }
    }
    const handleGameLengthChange = (ev) => {
        setGameHours(parseInt(ev.target.value));
        if(possibleOutcomes){
            resetOutcomes();
        }
    }

    const resetOutcomes = () => {
        setPossibleOutcomes(new Map());
        setPossibleOutcomesElements([]);
        // setExpectedValue('');
    }

    const calculateGamesCount = () => {
        if(betsPerHour === 0) return 1;
        return gameHours * betsPerHour;
    }

    const handleRouletteTypeChange = (ev) => {
        globalDataDispatch({
            type: 'setRouletteType',
            payload: ev.target.value
        })
    }

    const simulateSpin = (ev) => {
        const bets = getBets(editor);
        let winningNumber = configuration.numbers[Math.floor(Math.random()*configuration.numbers.length)];
        let simulatedProfit = simulateNumberProfit(bets, winningNumber.number);
        globalDataDispatch({
            type: 'setWinningNumber',
            payload: winningNumber
        });
        setWinningNumber(winningNumber);
        setSimulationValue((prev) => {
            return prev+simulatedProfit
        });
        setWinningNumberHistory((prev) => {
            return [winningNumber, ...prev]
        })

    }

    const resetSimulation = () => {
        setSimulationValue(0);
        setWinningNumber(null);
        setWinningNumberHistory([]);
        globalDataDispatch({
            type: 'setWinningNumber',
            payload: null
        });
    }


    let rouletteSelect = [];
    for(let type in RouletteConfiguration){
        rouletteSelect.push(
            <option key={type} value={type}>{RouletteConfiguration[type].name}</option>
        )
    }


    const gameOptionElements = [];
    for(let gameOptionKey in gameOptions){
        gameOptionElements.push(
            <option key={gameOptionKey} value={gameOptionKey}>{gameOptions[gameOptionKey].label}</option>
        )
    }

    return (
        <div className="Calculator">

            <div className="Calculator__box">
                <label htmlFor="roulette_select" className="Calculator__select-label">Roulette type:</label>

                <select id="roulette_select" className="Calculator__select Calculator__select--mb0" value={globalData.rouletteType} onChange={handleRouletteTypeChange}>
                    {rouletteSelect}
                </select>
            </div>

            <div className="Calculator__box Calculator__box--flex">
                <span className="Calculator__title">Your total bet: </span>
                <span className="Calculator__total-bet">{globalData.currency + globalData.totalBet}</span>
            </div>

            <div className="Calculator__box">

                <label htmlFor="game_option" className="Calculator__select-label">Game option:</label>
                <select id="game_option" className="Calculator__select" value={gameOption} onChange={handleGameOptionChange}>
                    {gameOptionElements}
                    {/*<option value="0">Single Bet</option>*/}
                    {/*<option value="1">Simulation</option>*/}
                    {/*<option value="30">Live In-Person Casino (30 bets per hour)</option>*/}
                    {/*<option value="110">Online With Dealer (110 bets per hour)</option>*/}
                    {/*<option value="220">Online Without Dealer (220 bets per hour)</option>*/}
                </select>

                {betsPerHour > 0 &&
					<>
						<label htmlFor="game_length" className="Calculator__select-label">Game option:</label>
						<select id="game_length" className="Calculator__select" value={gameHours} onChange={handleGameLengthChange}>
							<option value="1">1 hour</option>
							<option value="2">2 hours</option>
							<option value="3">3 hours</option>
							<option value="4">4 hours</option>
						</select>
					</>
                }

                {isSimulation ?
                    <button className="Calculator__button" onClick={simulateSpin}>
                        Spin <CheckIcon/>
                    </button>

                    :
                    <button className="Calculator__button" onClick={getPossibleOutcomesElements}>
                        Calculate <CheckIcon/>
                    </button>
                }
            </div>

            {!!possibleOutcomesElements.length &&
				<div className="Calculator__box">
                {betsPerHour > 0 ?
                        <span className="Calculator__title">Playing this strategy for {gameHours} {gameHours > 1 ? 'hours' : 'hour'}:</span>
                        :
                        <span className="Calculator__title">On each spin, you have:</span>
                    }
					<ul className="Calculator__results">
                        {possibleOutcomesElements}
					</ul>
				</div>
            }

            {isSimulation && winningNumber !== null &&
				<div className="Calculator__box">
					<span className="Calculator__title">Winning number: {winningNumber.label} <span
						style={{color: winningNumber.color}}>{winningNumber.color}</span></span>
					<div>
                        <span className="Calculator__title">
                           {simulationValue >= 0 ?
                               <span>Total Profit: <span
                                   style={{color: 'green'}}>{globalData.currency}{simulationValue}</span></span>
                               :
                               <span>Total Loss: <span
                                   style={{color: 'red'}}>{globalData.currency}{Math.abs(simulationValue)}</span></span>
                           }
                        </span>
					</div>

                    {winningNumberHistory.length > 0 &&
                        <div className="Calculator__winning-numbers">
                            {winningNumberHistory.map((winningNumberItem, index) => {
                                return (
                                    <div className="Calculator__winning-numbers__item" style={{background: winningNumberItem.color}} key={index}>{winningNumberItem.label}</div>
                                )
                            })}
                        </div>
                    }

					<button className="Calculator__button Calculator__button--secondary" style={{marginTop: '28px'}} onClick={resetSimulation}>
						Reset loss/profit
					</button>

				</div>
            }


        </div>
    )
}
