import {BoardField, CoinField, CurrentCoinField} from "../objects/fabricExtended.ts";
import {FabricJSEditor} from "fabricjs-react";

export function recalculatePositions(positions, scale: number)
{
    if(!positions.objects) return positions;
    for(let object of positions.objects){
        const prevScale = object.scaleX;
        object.scaleX = scale;
        object.scaleY = scale;
        object.left = object.left / prevScale * scale;
        object.top = object.top / prevScale * scale;

        if(object.type === 'currentCoinField'){
            let currentCoinField = object as CurrentCoinField;
            currentCoinField.coinData.defaultPosition.x = currentCoinField.coinData.defaultPosition.x / prevScale * scale;
            currentCoinField.coinData.defaultPosition.y = currentCoinField.coinData.defaultPosition.y / prevScale * scale;
        }

    }
    return positions;
}

export function *uniqueIDGenerator(){
    let i = 0;
    while (true) yield i++;
}

const uniqueIDGeneratorInstance = uniqueIDGenerator();
export const uniqueID = () => uniqueIDGeneratorInstance.next().value.toString();

export function uniqueValues(items: Array<any>): Array<any>
{
    return items.filter((item, index, array) => { return array.indexOf(item) === index })
}


export type AllBetsType = {
    [key: string]: {
        value: number,
        field: BoardField
    }
}

export const getBets = (editor: FabricJSEditor | undefined): AllBetsType => {
    const bets = {};
    const fields = {};
    editor?.canvas.getObjects('boardField').forEach((object: BoardField) => {
        fields[object.boardData.id] = object;
    })
    editor?.canvas.getObjects('coinField').forEach((object: CoinField) => {
        bets[object.betData.idField] = {
            value: object.betData.value,
            field: fields[object.betData.idField],
        };
    })

    return bets;
}

export const getTotalBets = (editor: FabricJSEditor | undefined): number => {
    let total = 0;
    editor?.canvas.getObjects('coinField').forEach((object: CoinField) => {
        total += object.betData.value;
    })

    return total;
}

export const MathFactorial = (num) => {
    let rval= 1;
    for (var i = 2; i <= num; i++)
        rval = rval * i;
    return rval;
}

export const isIOS = () => {
    return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(navigator.platform)
        // iPad on iOS 13 detection
        || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}
