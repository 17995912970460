import {useEffect} from "react";
import {FabricJSEditor} from "fabricjs-react";
import {Image} from "fabric/fabric-impl";
import {useGlobal, useGlobalDispatch} from "../context/GlobalContext.tsx";
import {CurrentCoinField} from "../objects/fabricExtended.ts";
import {getRouletteConfiguration, RouletteType} from "../data/RouletteConfiguration.ts";


export default function useEditorResize(editor: FabricJSEditor | undefined, type: RouletteType)
{
    const globalDataDispatch = useGlobalDispatch();
    const globalData = useGlobal();

    const configuration = getRouletteConfiguration(type);

    useEffect(() => {
        const handleEditorResize = (ev: UIEvent) => {
            const img = editor?.canvas.backgroundImage as Image;
            const imgWidth = img.width + configuration.widthModifier as number;

            const width = configuration.width > window.innerWidth ? window.innerWidth : configuration.width;
            const height = width / globalData.aspectRatio;
            editor?.canvas.setWidth(width);
            editor?.canvas.setHeight(height);

            const scale = width / imgWidth;
            img.scaleY = scale;
            img.scaleX = scale;

            if(editor){
                editor.canvas.backgroundImage = img;
            }

            editor?.canvas.getObjects().forEach((object) => {
                const scaleX = object.scaleX as number;
                const scaleY = object.scaleY as number;
                const left = object.left as number;
                const top = object.top as number;

                object.scale(scaleX / globalData.scale * scale)
                // object.scaleX = scaleX / globalData.scale * scale;
                // object.scaleY = scaleY / globalData.scale * scale;

                object.left = left / globalData.scale * scale;
                object.top = top / globalData.scale * scale;

                if(object.type === 'currentCoinField'){
                    let currentCoinField = object as CurrentCoinField;
                    currentCoinField.coinData.defaultPosition.x = currentCoinField.coinData.defaultPosition.x / globalData.scale * scale;
                    currentCoinField.coinData.defaultPosition.y = currentCoinField.coinData.defaultPosition.y / globalData.scale * scale;
                }
            });

            globalDataDispatch({
                type: 'setScaleAndAspectRatio',
                payload: {
                    scale: scale,
                    aspectRatio: globalData.aspectRatio,
                }
            });
        }
        window.addEventListener('resize', handleEditorResize);
        return () => {
            window.removeEventListener('resize', handleEditorResize)
        }
    }, [editor, globalData]);
}
