import europeanRoulettePositions from './positions/EuropeanRoulette.json';
import americanRoulettePositions from './positions/AmericanRoulette.json';
import tripleRoulettePositions from './positions/TripleRoulette.json';

import europeanRouletteNumbers from './numbers/EuropeanRoulette.json';
import americanRouletteNumbers from './numbers/AmericanRoulette.json';
import tripleRouletteNumbers from './numbers/TripleRoulette.json';

export type RouletteType = 'european' | 'american' | 'triple'

type CoinSizeFontRangeType = {
    min: number,
    max: number,
    font: number
}
export type RouletteFieldColorType = 'black' | 'red' | 'green'
export type RouletteFieldNumberType = {
    number: number,
    color: RouletteFieldColorType,
    label: string
}
export type RouletteConfigurationType = {
    name: string,
    width: number,
    board: string,
    scale: number,
    widthModifier: number,
    positions: any,
    coinSize: number,
    numbers: RouletteFieldNumberType[],
    coinFontSizeRanges: CoinSizeFontRangeType[]
}

export type RouletteConfigurationsType = {
    [key: RouletteType]: RouletteConfigurationType
}
export const RouletteConfiguration: RouletteConfigurationsType = {
    european: {
        name: 'European Roulette (single zero)',
        width: 412,
        board: 'european-roulette.png',
        scale: 2,
        get widthModifier() {
            return window.innerWidth > 900 ? 30 : 30
        },
        positions: europeanRoulettePositions,
        numbers: europeanRouletteNumbers,
        coinSize: 37,
        coinFontSizeRanges: [
            {min: -Infinity, max: 99, font: 12},
            {min: 100, max: Infinity, font: 10},
        ]
    },
    american: {
        name: 'American Roulette (double zero)',
        width: 412,
        board: 'american-roulette.png',
        scale: 2,
        get widthModifier() {
            return window.innerWidth > 900 ? 30 : 30
        },
        positions: americanRoulettePositions,
        numbers: americanRouletteNumbers,
        coinSize: 37,
        coinFontSizeRanges: [
            {min: -Infinity, max: 99, font: 12},
            {min: 100, max: Infinity, font: 10},
        ]
    },
    triple: {
        name: 'Triple Zero Roulette',
        width: 412,
        board: 'triple-zero-roulette.png',
        scale: 2,
        get widthModifier() {
            return window.innerWidth > 900 ? 30 : 30
        },
        positions: tripleRoulettePositions,
        numbers: tripleRouletteNumbers,
        coinSize: 37,
        coinFontSizeRanges: [
            {min: -Infinity, max: 99, font: 12},
            {min: 100, max: Infinity, font: 10},
        ]
    },
}

export const getRouletteConfiguration = (type: RouletteType): RouletteConfigurationType => {
    return RouletteConfiguration[type]
}
