// import 'vite/modulepreload-polyfill'
import React from 'react'
import ReactDOM from 'react-dom/client'
import RouletteSelect from './js/components/RouletteSelect.tsx'
import {GlobalProvider} from "./js/context/GlobalContext.tsx";

import './scss/index.scss'


const containers = document.querySelectorAll(`[data-roulette-calculator]`);
containers.forEach((container) => {

    ReactDOM.createRoot(container).render(
        // <React.StrictMode>
            <GlobalProvider pluginUrl={container.getAttribute('data-roulette-calculator')}>
                <RouletteSelect />
            </GlobalProvider>
        // </React.StrictMode>
    )
})


