import React, {createContext, useContext, useReducer} from 'react';
import {BoardField, CoinField, CurrentCoinField} from "../objects/fabricExtended.ts";
import {getTotalBets} from "../data/DataUtils.ts";
import {FabricJSEditor} from "fabricjs-react";
import {getBoardFieldCoinByID, getCoinField, modifyCoinField} from "../objects/CoinField.ts";
import {GlobalDataInterface, useGlobal} from "./GlobalContext.tsx";
import {getBoardFieldByID} from "../objects/BoardField.ts";
import {RouletteConfigurationType} from "../data/RouletteConfiguration.ts";


type HistoryEventType = {
    action: 'add' | 'subtract' | 'clear',
    value: number,
    fieldId: string
}
type HistoryEventsType = HistoryEventType[]

const historyEventsData: HistoryEventsType = []
const HistoryEventsContext = createContext<HistoryEventsType>(historyEventsData);

//@ts-ignore
const HistoryEventsDispatchContext = createContext(null);


interface HistoryEventsProviderProps {
    children: React.ReactNode;
}

export function HistoryEventsProvider(props: HistoryEventsProviderProps) {

    //@ts-ignore
    const [historyEventsDataProvider, dispatch] = useReducer<HistoryEventsType, (state: HistoryEventsType, action: HistoryEventsReducerActionInterface) => any>(
        historyEventsReducer,
        [
            ...historyEventsData,
        ]
    );
    return (
        <HistoryEventsContext.Provider value={historyEventsDataProvider}>
            <HistoryEventsDispatchContext.Provider value={dispatch}>
                {props.children}
            </HistoryEventsDispatchContext.Provider>
        </HistoryEventsContext.Provider>
    );
}

export function useHistoryEvents() {
    return useContext<HistoryEventsType>(HistoryEventsContext);
}

export function useHistoryEventsDispatch() {
    return useContext<(param: HistoryEventsReducerActionInterface) => void>(HistoryEventsDispatchContext);
}

interface HistoryEventsReducerActionInterface {
    type: HistoryEventsReducerActionTypes,
    payload: {
        historyEvent?: HistoryEventType,
        globalData: GlobalDataInterface,
        globalDataDispatch: any,
        configuration: RouletteConfigurationType
        editor: FabricJSEditor | undefined
    }
}
type HistoryEventsReducerActionTypes = 'add' | 'undo' | 'clear'


const getBoardFieldCoinByIdField = (id: string, editor: FabricJSEditor | undefined): null | CoinField => {

    for(const object of editor?.canvas.getObjects('coinField')){
        if (object.type !== 'coinField') continue;
        const coinField: CoinField = object as CoinField;
        if (coinField.betData.idField === id) {
            return coinField;
        }
    }

    return null;
}
function historyEventsReducer(state: HistoryEventsType, action: HistoryEventsReducerActionInterface) {
    switch (action.type) {
        case 'add': {
            return [...state, action.payload.historyEvent];
        }
        case 'undo': {
            const historyEvent = state.pop();
            if(!historyEvent){
                return [];
            }

            switch (historyEvent.action) {
                case 'add': {
                    const coinField = getBoardFieldCoinByID(historyEvent.fieldId, action.payload.editor);
                    if(coinField){
                        modifyCoinField(coinField, -historyEvent.value, action.payload.editor, action.payload.globalData).then((coinField) => {
                            action.payload.editor?.canvas.requestRenderAll();
                            action.payload.globalDataDispatch({
                                type: 'recalculateTotalBet',
                                payload: action.payload.editor,
                            })
                        })
                    }
                    break;
                }
                case 'subtract': {
                    let coinField = getBoardFieldCoinByID(historyEvent.fieldId, action.payload.editor);
                    if(coinField){
                        modifyCoinField(coinField, historyEvent.value, action.payload.editor, action.payload.globalData).then((coinField) => {
                            action.payload.editor?.canvas.requestRenderAll();
                            action.payload.globalDataDispatch({
                                type: 'recalculateTotalBet',
                                payload: action.payload.editor,
                            })
                        })
                    }else{
                        const target = getBoardFieldByID(historyEvent.fieldId, action.payload.editor);
                        if(target){
                            getCoinField(historyEvent.value, target, action.payload.globalData, action.payload.configuration).then((coinField) => {
                                if(coinField){
                                    action.payload.editor?.canvas.add(coinField);
                                }
                            })
                        }


                    }
                    break;
                }
                case 'clear': {
                    const target = getBoardFieldByID(historyEvent.fieldId, action.payload.editor);
                    if(target){
                        getCoinField(historyEvent.value, target, action.payload.globalData, action.payload.configuration).then((coinField) => {
                            if(coinField){
                                action.payload.editor?.canvas.add(coinField);

                                action.payload.globalDataDispatch({
                                    type: 'recalculateTotalBet',
                                    payload: action.payload.editor,
                                })
                            }
                        })
                    }
                }
            }

            return [...state];
        }
        case 'clear': {

            action.payload.editor?.canvas.getObjects('coinField').forEach((coinField: CoinField) => {
                action.payload.editor?.canvas.remove(coinField);
            })

            action.payload.globalDataDispatch({
                type: 'recalculateTotalBet',
                payload: action.payload.editor,
            })

            return [];
        }


        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}


