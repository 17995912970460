import {useEffect} from "react";
import {FabricJSEditor} from "fabricjs-react";
import {IEvent, Image} from "fabric/fabric-impl";
import {GlobalDataInterface, useGlobal, useGlobalDispatch} from "../context/GlobalContext.tsx";
import fabric, {BoardField, CoinField, CurrentCoinField} from "../objects/fabricExtended.ts";
import {
    CoinValues,
    getBoardFieldCoin,
    getCoinField,
    getProperCoinPattern,
    modifyCoinField
} from "../objects/CoinField.ts";
import useDataHistory from "./useDataHistory.ts";
import {getRouletteConfiguration, RouletteType} from "../data/RouletteConfiguration.ts";
import {useHistoryEvents, useHistoryEventsDispatch} from "../context/ActionsHistoryContext.tsx";


enum MouseButton {
    Left = 1,
    Middle = 2,
    Right = 3
}

export default function useBoardEvents(editor: FabricJSEditor | undefined, type: RouletteType)
{

    const globalData = useGlobal();
    const globalDataDispatch = useGlobalDispatch();
    const historyEvents = useHistoryEvents();
    const historyEventsDispatch = useHistoryEventsDispatch();
    const configuration = getRouletteConfiguration(type);



    const getCurrentCoinValue = () => {
        if(globalData.currentCoin){
            return globalData.currentCoin.coinData.value
        }
        return 0;
    }
    const handleBoardFieldLeftClick = async (ev: IEvent<MouseEvent>) => {
        if(ev.button !== MouseButton.Left) return;
        if(ev.target?.type !== 'boardField') return;

        const coinValue = getCurrentCoinValue();
        const target = ev.target as BoardField;

        let coinField = getBoardFieldCoin(target, editor)
        if(coinField){
            await modifyCoinField(coinField, coinValue, editor, globalData);
        }else{
            coinField = await getCoinField(coinValue, target, globalData, configuration);
            if(coinField){
                editor?.canvas.add(coinField);

            }
        }

        historyEventsDispatch({
            type: 'add',
            payload: {
                historyEvent: {
                    action: 'add',
                    fieldId: target.boardData.id,
                    value: coinValue
                },
                editor: editor,
                configuration: configuration,
                globalData: globalData,
                globalDataDispatch: globalDataDispatch
            }
        })

        editor?.canvas.requestRenderAll();
    }
    const handleBoardLeftClick = (ev: IEvent<MouseEvent>) => {
        if(ev.button !== MouseButton.Left) return;
        if(ev.target !== null) return; //click in board, not any field and coins

        if(globalData.currentCoin){
            moveCoinToDefaultPosition(globalData.currentCoin);
            globalDataDispatch({
                type: "setCurrentCoin",
                payload: {
                    currentCoin: null
                }
            })
        }
    }

    const handleBoardFieldRightClick = async (ev: IEvent<MouseEvent>) => {
        if(ev.button !== MouseButton.Right) return;
        if(ev.target?.type !== 'boardField') return;

        const coinValue = getCurrentCoinValue();
        const target = ev.target as BoardField;

        const coinField = getBoardFieldCoin(target, editor)
        if(coinField){
            let coinModify = coinValue;
            if(coinField.betData.value <= coinValue){
                coinModify = coinField.betData.value;
            }
            await modifyCoinField(coinField, -coinValue, editor, globalData);

            historyEventsDispatch({
                type: 'add',
                payload: {
                    historyEvent: {
                        action: 'subtract',
                        fieldId: target.boardData.id,
                        value: coinModify
                    },
                    editor: editor,
                    configuration: configuration,
                    globalData: globalData,
                    globalDataDispatch: globalDataDispatch
                }
            })
        }



        editor?.canvas.requestRenderAll();
    }

    const handleBoardFieldMiddleClick = async (ev: IEvent<MouseEvent>) => {
        if(ev.button !== MouseButton.Middle) return;
        if(ev.target?.type !== 'boardField') return;
        const target = ev.target as BoardField;

        const coinField = getBoardFieldCoin(target, editor)
        if(coinField){
            const prevValue = coinField.betData.value;
            await modifyCoinField(coinField, -Infinity, editor, globalData);

            historyEventsDispatch({
                type: 'add',
                payload: {
                    historyEvent: {
                        action: 'clear',
                        fieldId: target.boardData.id,
                        value: prevValue
                    },
                    editor: editor,
                    configuration: configuration,
                    globalData: globalData,
                    globalDataDispatch: globalDataDispatch
                }
            })
        }

        editor?.canvas.requestRenderAll();
    }


    const moveCoinToDefaultPosition = (coin: CurrentCoinField) => {
        coin.animate({
            left: coin.coinData.defaultPosition.x,
            top: coin.coinData.defaultPosition.y
        }, {
            duration: 300,
            onChange: editor?.canvas.renderAll.bind(editor?.canvas),
        });
    }
    const handleCurrentCoinFieldLeftClick = (ev: IEvent<MouseEvent>) => {
        if(ev.button !== MouseButton.Left) return;
        if(ev.target?.type !== 'currentCoinField') return;
        const target = ev.target as CurrentCoinField;

        if(globalData.currentCoin){
            moveCoinToDefaultPosition(globalData.currentCoin);
        }

        //@ts-ignore
        target.coinData.defaultPosition.x = target.left;
        //@ts-ignore
        target.coinData.defaultPosition.y = target.top;

        globalDataDispatch({
            type: "setCurrentCoin",
            payload: {
                currentCoin: target
            }
        })

    }

    const handleBoardFieldMouseOver = (ev: IEvent<MouseEvent>) => {
        const target = ev.target as BoardField | null;
        if (!target) return;
        if (target.type !== 'boardField') return;

        target.boardData.bet.forEach((number) => {
            editor?.canvas.getObjects('boardField').forEach((object: BoardField) => {
                if (object.type !== 'boardField') return;

                if (object.boardData.number === number) {
                    object.set('opacity', 0.5);
                }
            });
        })

        editor?.canvas.renderAll();
    }
    const handleBoardFieldMouseOut = (ev: IEvent<MouseEvent>) => {
        const target = ev.target as BoardField | null;
        if (!target) return;
        if (target.type !== 'boardField') return;

        target.boardData.bet.forEach((number) => {
            editor?.canvas.getObjects('boardField').forEach((object: BoardField) => {
                if (object.type !== 'boardField') return;

                if (object.boardData.number === number) {
                    object.set('opacity', 0);
                }
            });
        })

        editor?.canvas.requestRenderAll();
    }

    const handleFollowCoinMouseMove = (ev: IEvent<MouseEvent>) => {
        if(!globalData.currentCoin) return;
        // console.log(globalData.currentCoin);
        globalData.currentCoin.left = ev.e.offsetX - (globalData.currentCoin.width / 2 * globalData.scale);
        globalData.currentCoin.top = ev.e.offsetY - (globalData.currentCoin.height / 2 * globalData.scale);
        globalData.currentCoin.dirty = true;
        editor?.canvas.requestRenderAll();
    }

    const handleMouseOver = (ev: IEvent<MouseEvent>) => {
        handleBoardFieldMouseOver(ev);
        handleFollowCoinMouseMove(ev);
    }
    const handleMouseOut = (ev: IEvent<MouseEvent>) => {
        handleBoardFieldMouseOut(ev);
    }
    const handleMouseClick = async (ev: IEvent<MouseEvent>) => {

        //@ts-ignore
        if(window.shouldIgnoreTouchEvent) return;

        await handleBoardFieldLeftClick(ev);
        handleBoardLeftClick(ev);
        await handleBoardFieldRightClick(ev);
        await handleBoardFieldMiddleClick(ev);
        handleCurrentCoinFieldLeftClick(ev);

        globalDataDispatch({
            type: 'recalculateTotalBet',
            payload: editor,
        })
    }

    const handleMouseMove = (ev: IEvent<MouseEvent>) => {
        handleFollowCoinMouseMove(ev);
    }

    const glowBoardField = (number) => {
        editor?.canvas.getObjects('boardField').forEach((object: BoardField) => {
            if (object.type !== 'boardField') return;

            if (object.boardData.number === number) {
                object.set('opacity', 0.5);
            }else{
                object.set('opacity', 0);
            }
        });
        editor?.canvas.renderAll();
    }

    useEffect(() => {
        editor?.canvas.on('mouse:over', handleMouseOver);
        editor?.canvas.on('mouse:move', handleMouseMove);
        editor?.canvas.on('mouse:out', handleMouseOut);
        editor?.canvas.on('mouse:up', handleMouseClick);

        glowBoardField(globalData.winningNumber?.number);

        return () => {
            editor?.canvas.off('mouse:over', handleMouseOver);
            editor?.canvas.off('mouse:move', handleMouseMove);
            editor?.canvas.off('mouse:out', handleMouseOut);
            editor?.canvas.off('mouse:up', handleMouseClick);
        }

    }, [editor, globalData]);
}
