import {useRef, useState} from "react";
import {isIOS} from "../data/DataUtils.ts";

export default function CanvasTouchScrollFixer(props){

    const [startPosition, setStartPosition] = useState<number>(0);
    const [touchStartPosition, setTouchStartPosition] = useState<number>(0);
    const [prevTouchPosition, setPrevTouchPosition] = useState<number>(0);
    const [logs, setLogs] = useState<string[]>([]);
    const wrapper = useRef(null);
    const handleTouchStart = (ev) => {
        if(isIOS()){
            setStartPosition(wrapper.current.scrollTop);

        }else{
            setStartPosition(window.scrollY);
        }
        setTouchStartPosition(ev.nativeEvent.touches[0].clientY);
        setPrevTouchPosition(ev.nativeEvent.touches[0].clientY);
    }


    const handleTouchMove = (ev) => {

        if(isIOS()){
            if(Math.abs(touchStartPosition - ev.nativeEvent.touches[0].clientY) > 10){
                //@ts-ignore
                window.shouldIgnoreTouchEvent = true;

                let nextMove = ev.nativeEvent.touches[0].clientY;
                let brake = 20;
                if(Math.abs(prevTouchPosition - nextMove) > brake){
                    if(prevTouchPosition > nextMove){
                        brake *= -1;
                    }
                    nextMove = prevTouchPosition + brake;
                }
                setPrevTouchPosition(nextMove);

                // window.scrollBy({
                //     // top: startPosition + (ev.nativeEvent.touches[0].clientY*-1) - touchStartPosition * -1,
                //     top: (nextMove*-1) - prevTouchPosition * -1,
                //     behavior: 'instant'
                // })

                let scrollTop = startPosition + (ev.nativeEvent.touches[0].clientY*-1) - touchStartPosition * -1;
                console.log(scrollTop);
                if(scrollTop <= 0){
                    window.scrollBy({
                        top: -10
                    })
                    scrollTop = 0;
                }
                if(scrollTop > wrapper.current.scrollHeight - wrapper.current.clientHeight){
                    window.scrollBy({
                        top: 10
                    })
                }
                wrapper.current.scrollTop = scrollTop;
                // const log = document.createElement('p');
                // setLogs(['Start window: '+startPosition+' Touch start: '+touchStartPosition+' Current position: '+ev.nativeEvent.touches[0].clientY+' Move to: '+(startPosition + (ev.nativeEvent.touches[0].clientY*-1) - touchStartPosition * -1), ...logs]);
            }

        }else{
            if(Math.abs(touchStartPosition - ev.nativeEvent.touches[0].clientY) > 10){
                //@ts-ignore
                window.shouldIgnoreTouchEvent = true;

                let nextMove = ev.nativeEvent.touches[0].clientY;
                setPrevTouchPosition(nextMove);

                window.scrollBy({
                    // top: startPosition + (ev.nativeEvent.touches[0].clientY*-1) - touchStartPosition * -1,
                    top: (nextMove*-1) - prevTouchPosition * -1,
                    behavior: 'instant'
                })
            }

        }




    }
    const handleTouchEnd = (ev) => {
        setTimeout(() => {
            //@ts-ignore
            window.shouldIgnoreTouchEvent = false;
        }, 50)
    }

    let divStyle = isIOS() ? {overflow: 'hidden', maxHeight: '100svh'} : {};
    return (
        <>
            <div style={divStyle} ref={wrapper} onTouchMove={handleTouchMove} onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                {props.children}
            </div>

        </>

    )
}
